export const seo = {
  url: 'transport',
  title: {
    en: 'Transport | Services | Types | Countries',
    es: 'Transporte | Servicios | Tipos | Los paises',
    ro: 'Transport | Servicii | Tipuri | tari',
  },
  desc: {
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
    es: 'Descubre los tipos de transporte, servicios integrales de transporte en diversas industrias. Compruebe cómo es el transporte en los países europeos.',
    ro: 'Aflați despre tipurile de transport, servicii complete de transport în diverse industrii. Verificați cum arată transportul în țările europene.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'transport types',
    'transport services',
    'transport in europe',
    'transport in countries',
  ],
}

export const intro = {
  title: {
    en: 'Transport',
    es: 'Transporte',
    ro: 'Transport',
  },
  desc: {
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
    es: 'Descubre los tipos de transporte, servicios integrales de transporte en diversas industrias. Compruebe cómo es el transporte en los países europeos.',
    ro: 'Aflați despre tipurile de transport, servicii complete de transport în diverse industrii. Verificați cum arată transportul în țările europene.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Transport Omida Logistics - Polish transport company. Take advantage of TFL services and deliver your transport to the destination.',
        es: 'Transporte Omida Logistics - empresa de transporte polaca. Aproveche los servicios de TFL y entregue su transporte en el destino.',
        ro: 'Transport Omida Logistics - Companie de transport poloneză. Profită de serviciile TFL și livrează-ți transportul la destinație.',
      },
      texts: [
        {
          en: '<div> Omida Logistics is a Polish transport company. We deal with the transport of goods by road, rail and intermodal transport. As part of our services, we organize a multitude of different types of transport, incl. <strong>full truckload transport</strong> and <strong> less than truckload transport </strong>. </div>',
          es: '<div> Omida Logistics es una empresa de transporte polaca. Nos ocupamos del transporte de mercancías por carretera, ferrocarril y transporte intermodal. Como parte de nuestros servicios, organizamos una multitud de diferentes tipos de transporte, incl. <strong>transporte de carga completa</strong> y <strong>transporte de carga parcial</strong>. </div>',
          ro: '<div> Omida Logistics este o companie de transport poloneză. Ne ocupăm de transportul de mărfuri rutier, feroviar și transport intermodal. Ca parte a serviciilor noastre, organizăm o multitudine de tipuri diferite de transport, inclusiv <strong>transport complet cu camionul</strong> și <strong> mai puțin decât transportul cu camionul </strong>. </div>',
        },
        {
          en: '<div> Our branches are located in over 20 cities all over Poland. From 2019, we have a <strong> A-class warehouse near Warsaw </strong>, handling all logistics processes of our clients. Our strength is <strong> FTL full truckload transport across Europe </strong>. This is where we are the best! </div>',
          es: '<div> Nuestras sucursales están ubicadas en más de 20 ciudades de toda Polonia. Desde 2019, contamos con un <strong> almacén de clase A cerca de Varsovia </strong>, que se ocupa de todos los procesos logísticos de nuestros clientes. Nuestro punto fuerte es el <strong> transporte de camiones completos FTL en toda Europa </strong>. ¡Aquí es donde somos los mejores! </div>',
          ro: '<div> Filialele noastre sunt situate în peste 20 de orașe din toată Polonia. Din 2019, avem un <strong>depozit de clasă A lângă Varșovia </strong>, care se ocupă de toate procesele logistice ale clienților noștri. Puterea noastră este <strong>transportul FTL cu camioane complete în toată Europa </strong>. Aici suntem cei mai buni! </div>',
        },
        {
          en: "<div> Looking to transport goods in Europe? Choose Omida Logistics. We focus on <strong> constant contact between the supervisor and the client and the team's specialization </strong> in serving a given industry! </div>",
          es: '<div> ¿Quiere transportar mercancías en Europa? Elija Logística Omida. ¡Nos enfocamos en el <strong> contacto constante entre el supervisor y el cliente y la especialización del equipo </strong> al servicio de una industria determinada! </div>',
          ro: '<div> Căutați să transportați mărfuri în Europa? Alegeți Omida Logistics. Ne concentrăm pe <strong> contactul constant între supervizor și client și specializarea echipei </strong> în deservirea unei anumite industrii! </div>',
        },
      ],
    },
    {
      headline: {
        en: 'Division of transport into types, services and industries',
        es: 'División del transporte en tipos, servicios e industrias',
        ro: 'Diviziunea transporturilor în tipuri, servicii și industrii',
      },
      texts: [
        {
          en: '<div>Transportation can be divided into types related to different services. The most popular types of transport are FTL and LTL. Transport can also be categorized based on <strong>specific industries and the products</strong> they manufacture or the industries in which they are used. Also read about <strong>transport in different countries</strong>. Use the buttons below to go to the relevant subpages.</div>',
          es: '<div>El transporte se puede dividir en tipos relacionados con diferentes servicios. Los tipos de transporte más populares son FTL y LTL. El transporte también se puede categorizar en función de <strong>industrias específicas y los productos</strong> que fabrican o las industrias en las que se utilizan. Lea también sobre <strong>transporte en diferentes países</strong>. Use los botones a continuación para ir a las subpáginas relevantes.</div>',
          ro: '<div>Transportul poate fi împărțit în tipuri legate de diferite servicii. Cele mai populare tipuri de transport sunt FTL și LTL. Transportul poate fi, de asemenea, clasificat în funcție de <strong>industriile specifice și de produsele</strong> pe care le fabrică sau de industriile în care sunt utilizate. Citiți și despre <strong>transportul în diferite țări</strong>. Utilizați butoanele de mai jos pentru a accesa subpaginile relevante.</div>',
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    en: 'Quote a transport',
    es: 'Evaluar el transporte',
    ro: 'Cotați un transport',
  },
  link: '/quote?form=open/',
}
